.project-boxes {
  overflow-y: auto;
  width: 33.3333%;
}

.project-box-wrapper {
  padding: 30px;
}

.project-box {
  border-radius: 30px;
  padding: 16px;
  background-color: #fee4cb;
}

.project-box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.project-box-content-header {
  text-align: center;
  margin-bottom: 16px;
}

.box-content-header {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  opacity: .7;
}

.box-content-subheader {
  font-size: 14px;
  line-height: 24px;
  opacity: .7;
}

.box-progress-bar {
  width: 100%;
  height: 4px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;
  margin: 8px 0;
}

.box-progress-header {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  margin: 0;
}

.box-progress {
  width: '60%', ;
  background-color: #ff942e;
  display: block;
  height: 4px;
  border-radius: 6px;
}

.box-progress-percentage {
  text-align: right;
  margin: 0;
  font-size: 10px;
  font-weight: 700;
  opacity: .7;
}

.project-box-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.participants {
  display: flex;
}

.participants img {
  border-radius: 50%;
  height: 28px;
  width: 28px;
  object-fit: cover;
  margin-right: -8px;
  border: 2px solid #fff;
}

.add-participant {
  color: '#ff942e';
  height: 28px;
  width: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed;
  background: none;
  margin-right: -8px;
  padding: 0;
  cursor: pointer;
}

.days-left {
  color: #ff942e;
  font-size: 14px;
  font-weight: 700;
}

/*------------------------------------------------------------------
[ Responsive ]*/



@media (max-width: 1500px) {
  .project-boxes {
    width: 50%;
  }
}

@media (max-width: 1100px) {
  .project-boxes {
    width: 100%;
  }

  .project-box-wrapper {
    padding: 20px 0px 20px 0px;
  }
}

@media (max-width: 992px) {
  .wrap {
    width: 100%;
  }
}
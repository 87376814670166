
* {
	margin: 0px; 
	padding: 0px; 
	box-sizing: border-box;
}

a{
  text-decoration: none;
}

body, html {
	height: 100%;
	font-family: Poppins-Regular, sans-serif;
}

.limiter {
    width: 100%;
    margin: 0 auto;
  }
  
  .container {
    width: 100%;  
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-color: #F2F2F2;
  }
  
  .wrap-dashboard {
    width: 60%;
    background-image: linear-gradient(to top, #fddb92 0%, #d1fdff 100%);
    border-radius: 10px;
    overflow: hidden;
    padding: 55px 55px 33px 55px;
  
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .sub_title{
    padding-top: 30px;
  }

  .events_container {
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-content: center;
    padding: 50px 0;
  }

  .cards-events-list {
    /* flex-basis: calc(30%); */
    margin: 0 15px 30px;
    overflow: hidden;
    border-radius: 28px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .card-event {
    display: block;
    width: 300px;
    max-width: 300px;
    padding: 30px 20px;
    background-color: #fff;
    overflow: hidden;
    position: relative;
  }

  .card-event:hover,
  .card-event:hover .card-event-item_members {
    text-decoration: none;
    color: black;
  }
  .card-event:hover .card-event-item_bg {
    -webkit-transform: scale(10);
    -ms-transform: scale(10);
    transform: scale(10);
  }
  .card-event-item_title {
    min-height: 30px;
    margin: 0 5px 25px;
    text-decoration: none;
    overflow: hidden;
    font-weight: bold;
    font-size: 25px;
    color: black;
    z-index: 2;
    position: relative;
  }
  .card-event-item_ownerName {
    font-size: 18px;
    color: black;
    z-index: 2;
    position: relative;
  }

  .card-event-item_members {
    font-weight: bold;
    color: #f9b234;
  
    -webkit-transition: color .5s ease;
    -o-transition: color .5s ease;
    transition: color .5s ease
  }
  .card-event-item_bg {
    height: 128px;
    width: 128px;
    background-color: #D6F9F2;
  
    z-index: 1;
    position: absolute;
    top: -75px;
    right: -75px;
  
    border-radius: 50%;
  
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
  }
  .cards-events-list:nth-child(2n) .card-event-item_bg {
    background-color: #F6E0A3;
  }
  .cards-events-list:nth-child(3n) .card-event-item_bg {
    background-color: #A7F9CE;
  }
  .cards-events-list:nth-child(4n) .card-event-item_bg {
    background-color: #DAE5FF;
  }
  .cards-events-list:nth-child(5n) .card-event-item_bg {
    background-color: #DAE5FF;
  }
  .cards-events-list:nth-child(6n) .card-event-item_bg {
    background-color: #FFACC2;
  }


  .dashboard_action_container{
    display: flex;
    justify-content: space-between;
  }




  @media (max-width: 992px) {
    .wrap-dashboard {
        width: 100%;
      }
  }

  @media (max-width: 1200px) {
      .cards-events-list {
        margin: 0 0px 30px;
      }

      .events_container {
        flex-direction: column;
      }
  }


$color-main : #f29c9c;

/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Poppins-Regular;
  src: url('../fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: Poppins-Medium;
  src: url('../fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: Poppins-Bold;
  src: url('../fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url('../fonts/poppins/Poppins-SemiBold.ttf');
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body,
html {
  height: 100%;
  font-family: Poppins-Regular, sans-serif;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: #F2F2F2;
}

.wrap_event_detail {
  width: 60%;
  background-color: #fff;
  ;
  border-radius: 10px;
  overflow: hidden;
  padding: 55px 55px 33px 55px;

  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.event_title {
  color: #3F414E;
  text-align: center;
}

.event_sub_title {
  color: #3F414E;
  text-align: left;
  font-weight: bold;
}

.event_input {
  border-bottom: 2px solid #adadad;
  font-family: Poppins-Regular;
  font-size: 15px;
  color: $color-main;
  line-height: 1.2;
  display: block;
  height: 45px;
  background: transparent;
  padding: 0 5px;
}

.projects-section-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;
  padding-top: 32px;
}

.projects-status {
  display: flex;
}

.item-status {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}

.status-number {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: black;
}

.status-type {
  position: relative;
  padding-right: 24px;
  color: #4a4a4a;
}

.grid_container {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 1100px) {
  .wrap_event_detail {
    width: 100%;
  }

  .projects-status {
    flex-direction: column;
  }

  .status-number {
    font-size: 20px;
  }
}
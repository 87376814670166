$color-main : #f29c9c;

.signup-container {
    background-color: #f9f9f9;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .signup-form {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 400px;
  }

  .error-message {
    font-family: Poppins-Regular;
    color: $color-main;
    font-size: 13px;
    line-height: 1.4;
    text-align: center;
  }

  /*---------------------------------------------*/

.btn-show-pass {
  font-size: 15px;
  color: #999999;
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 120px;
  right: 50px;
  padding-right: 5px;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}


  /*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 576px) {
  .wrap-login {
    padding: 20px 15px 20px 15px;
  }
  svg{
    height: 100px;
  }

  .btn-show-pass {
    top: 70px;
    right: 10px;
  }
}





  